import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participant-study-disclosure',
  templateUrl: './participant-study-disclosure.component.html',
  styleUrls: ['./participant-study-disclosure.component.css']
})
export class ParticipantStudyDisclosureComponent implements OnInit {
  isCheckedT1:boolean;
  isCheckedT2:boolean;
  isCheckedT3:boolean;
  constructor() { }

  ngOnInit() {
  }
  saveTerms():void{
  }
}
