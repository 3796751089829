import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticipantService } from 'src/services/participant.service';
import { studyModel, studyScheduleModel, studyRequirementsModel, studyCommitmentModel, screeningScheduleModel } from 'src/models/study.model';
import { participantModel, particpantResponse, disclosure, participantResponseModel, participantStratification } from 'src/models/participant.model';
import { NgForm, FormGroup, FormArray } from '@angular/forms';
import { questionModel, bindingModel } from 'src/models/question.model';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';
import { AdminService } from 'src/services/admin.service';
import { Location } from '@angular/common';
import { ParticipantDynamicValuesModel } from 'src/app/model/participant.model';

@Component({
  selector: 'app-participantstudydetail',
  templateUrl: './participant_study_detail.component.html',
  styleUrls: ['./participant_study_detail.component.css']
})
export class ParticipantStudyDetailComponent implements OnInit {

  frmGrpAns: FormGroup;
  frmAryAns: FormArray;
  participantId: string;
  studyId: string;
  showDemo: boolean = true;
  showDesclosure: boolean = false;
  showScreener: boolean = false;
  studyModel: studyModel;
  particpantResponse = new particpantResponse();
  pRSQuestion = new particpantResponse();
  studySchedule: studyScheduleModel[];
  public ParticipantDynamicModel: ParticipantDynamicValuesModel[] = [];
  studyRequirements: studyRequirementsModel[] = [];
  studyScreeningSchedule: screeningScheduleModel[] = [];
  studyCommitment: studyCommitmentModel[] = [];
  participant = new participantModel();
  questionModel: questionModel[];
  disclosure = new disclosure();
  questionModelbackup: questionModel[];
  participantStratification: participantStratification[] = [];
  index: number;
  questionModelbackupl: number;
  answersl: number;
  maxDate: Date;
  minDate: Date;
  demographicsForm: NgForm;
  radioAnswerId: String;
  radioAnswerText: string;
  dbquestionId: string;
  yearRange: string;
  pRModel = new participantResponseModel();
  wholeHeight: number;
  BMI: number;
  wholeBMI: string;
  qualifyFlag: boolean = false;
  enableBtnFlag: boolean = true;
  enableQABtnFlag: boolean = false;
  dobChecked: boolean = true;
  showStudyScheduleFlag: boolean = false;
  AgeMin: string;
  AgeMax: string;
  BMIMin: string;
  BMIMax: string;
  weightMin: string;
  weightMax: string;
  UserIdLogged: string = "";
  checkedDisclosure: number[] = [];
  selectedAnswer: number[] = [];
  isAdmin: boolean = false;
  public qBind: bindingModel[] = [];
  IsRefered: string = "false";

  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  dateReg = "(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}";

  @ViewChild("dobpicker", { static: true }) picker;

  toFormattedDate(iso: string) {
    const date = new Date(iso);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }
  open() {
    this.picker.open();
  }
  constructor(private route: ActivatedRoute, private router: Router, private participateservice: ParticipantService, private activeRoute: ActivatedRoute, private authGaurd: AuthGaurdService, private admin: AdminService, private location: Location) {

  }

  ngOnInit() {
    this.IsRefered = history.state.IsRefered === true ? "true" : "false";

    this.isAdmin = this.authGaurd.isAdmin.toLowerCase() === "true" ? true : false;
    this.getLogginedUserId();
    const longdate = JSON.stringify(this.participant.dob);
    this.yearRange = 1900 + ':' + (new Date().getFullYear());
    this.index = 0;
    this.participantId = '';
    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.studyId = this.route.snapshot.paramMap.get("studyid");
    this.getParticipantDynamicValues();
    this.admin.getQSDemographics(parseInt(this.studyId), this.isAdmin, this.IsRefered)
      .subscribe(res => {
        this.qBind = res.sort((a, b) => a.groupId - b.groupId)
        let groupId = Array.from(this.qBind.reduce((m, t) => m.set(t.groupId, t), new Map()).values());
        for (let k = 0; k < groupId.length; k++) {
          this.participantStratification.push(
            {
              age: "Any", weight: "Any", gender: "", bmi: "Any"
            }
          );

          let stratificationList = this.qBind.filter(x => x.groupId === groupId[k].groupId).map(x => Object.assign({}, x));

          for (let l = 0; l < stratificationList.length; l++) {
            switch (stratificationList[l].questionText) {
              case "Age":
                if ((stratificationList[l].answer1 === "-999.00" || stratificationList[l].answer1 === null) && (stratificationList[l].answer2 === "999.00" || stratificationList[l].answer2 === null)) {
                  this.participantStratification[k].age = 'Any';
                }
                else if (stratificationList[l].answer1 === "-999.00" || stratificationList[l].answer1 === null) {
                  this.participantStratification[k].age = "upto " + stratificationList[l].answer2.split('.')[0];
                }
                else if (stratificationList[l].answer2 === "999.00" || stratificationList[l].answer2 === null) {
                  this.participantStratification[k].age = stratificationList[l].answer1.split('.')[0] + '+';
                }
                else {
                  this.participantStratification[k].age = stratificationList[l].answer1.split('.')[0] + "-" + stratificationList[l].answer2.split('.')[0];
                }
                break;
              case "Weight":
                if ((stratificationList[l].answer1 === "-999.00" || stratificationList[l].answer1 === null) && (stratificationList[l].answer2 === "999.00" || stratificationList[l].answer2 === null)) {
                  this.participantStratification[k].weight = 'Any';
                }
                else if (stratificationList[l].answer1 === "-999.00" || stratificationList[l].answer1 === null) {
                  this.participantStratification[k].weight = "upto " + stratificationList[l].answer2;
                }
                else if (stratificationList[l].answer2 === "999.00" || stratificationList[l].answer2 === null) {
                  this.participantStratification[k].weight = stratificationList[l].answer1 + '+';
                }
                else {
                  this.participantStratification[k].weight = stratificationList[l].answer1 + "-" + stratificationList[l].answer2;
                }
                break;
              case "BMI":
                if ((stratificationList[l].answer1 === "-999.00" || stratificationList[l].answer1 === null) && (stratificationList[l].answer2 === "999.00" || stratificationList[l].answer2 === null)) {
                  this.participantStratification[k].bmi = 'Any';
                }
                else if (stratificationList[l].answer1 === "-999.00" || stratificationList[l].answer1 === null) {
                  this.participantStratification[k].bmi = "upto " + stratificationList[l].answer2;
                }
                else if (stratificationList[l].answer2 === "999.00" || stratificationList[l].answer2 === null) {
                  this.participantStratification[k].bmi = stratificationList[l].answer1 + '+';
                }
                else {
                  this.participantStratification[k].bmi = stratificationList[l].answer1 + "-" + stratificationList[l].answer2;
                }
                break;
              case "Gender":
                this.participantStratification[k].gender = stratificationList[l].answer1 === 'B' ? 'Both' : stratificationList[l].answer1 === 'M' ? 'Male' : stratificationList[l].answer1 === 'F' ? 'Female' : '-';
            }
          }
        }
      });

    if (this.isAdmin === false || this.IsRefered === "true") {

      this.participateservice.getStudyDetailsForParticipant(this.studyId).subscribe(resp => {
        this.studyModel = resp;
        this.studySchedule = JSON.parse(resp.studySchedule);
        for (let i = 0; i < this.studySchedule.length; i++) {
          if (this.studySchedule[i].followupDays !== '') {
            this.showStudyScheduleFlag = true;
            break;
          }
        }
        this.studyRequirements = JSON.parse(resp.studyRequirements);
        this.studyCommitment = JSON.parse(resp.studyCommitment);
        this.studyScreeningSchedule = JSON.parse(resp.screeningSchedule);
        for (let i = 0; i < this.studyScreeningSchedule.length; i++) {
          let screeningscheduleDays: string[] = this.studyScreeningSchedule[i].screeningscheduleDays.split('|').join(',  ').split(',');
          this.studyScreeningSchedule[i].screeningscheduleDays = screeningscheduleDays.toString();
        }
      },
        error => {
          this.router.navigate(['study-not-found'],
            { relativeTo: this.route, skipLocationChange: true });
        }
      );
    }
    else {
      this.participateservice.getStudyDetail(this.studyId).subscribe(resp => {
        this.studyModel = resp;
        this.studySchedule = JSON.parse(resp.studySchedule);
        for (let i = 0; i < this.studySchedule.length; i++) {
          if (this.studySchedule[i].followupDays !== '') {
            this.showStudyScheduleFlag = true;
            break;
          }
        }
        this.studyRequirements = JSON.parse(resp.studyRequirements);
        this.studyCommitment = JSON.parse(resp.studyCommitment);
        this.studyScreeningSchedule = JSON.parse(resp.screeningSchedule);
        for (let i = 0; i < this.studyScreeningSchedule.length; i++) {
          let screeningscheduleDays: string[] = this.studyScreeningSchedule[i].screeningscheduleDays.split('|').join(',  ').split(',');
          this.studyScreeningSchedule[i].screeningscheduleDays = screeningscheduleDays.toString();
        }
      },
        error => {
          this.router.navigate(['study-not-found'],
            { relativeTo: this.route, skipLocationChange: true });
        }
      );
    }
    this.participant.studyId = this.studyId;
  }



  getParticipantDynamicValues() {
    this.participateservice.getParticipantDynamicValues(this.studyId, this.isAdmin, this.IsRefered)
      .subscribe(res => {        
        this.ParticipantDynamicModel = res.filter(x => (x.D_Option && x.D_Option != '' && x.D_Option != '2'));        
      });

  }

  buttonEnable(event) {
    if (this.questionModelbackup[this.index].questionDesc.trim() !== '' && this.questionModelbackup[this.index].questionDesc !== null) {
      this.enableQABtnFlag = true;
    }
    else {
      this.enableQABtnFlag = false;
    }
  }



  getLogginedUserId() {

    this.admin.getUserId()
      .subscribe(response => {

        this.UserIdLogged = String(response);

      });
  }


  cancel() {
    this.router.navigate(['/adminhome/studydetail/', this.studyId], { fragment: 'display', skipLocationChange: true });
  }
  onChangeQA(event, answerId) {
    if (event && answerId) {
      this.enableQABtnFlag = true;
    }

    else {
      this.enableQABtnFlag = false;
    }
  }

  onChangeFreeTxt(event, answerType) {
    if(answerType !== 'Radio-Text')
      this.enableQABtnFlag = event.target.value !== '';    
  }
  checkInch() {
    if (!this.participant.inches) {
      this.participant.inches = 0;
    }
  }
  calculateBMI() {

    if ((this.participant.height !== null && this.participant.height > 0) && (this.participant.inches !== null) && (this.participant.weight !== null && this.participant.weight > 0)) {
      this.wholeHeight = (this.participant.height * 12) + this.participant.inches;
      this.BMI = ((this.participant.weight / Math.pow(this.wholeHeight, 2)) * 703);
      this.wholeBMI = this.BMI.toFixed(2);
    }
    else {
      this.wholeBMI = '';
    }
  }
  navigateCriteria(): void {
    if (!this.particpantResponse.participantResult && this.particpantResponse.questionType === 'Demographics') {
      this.router.navigate(['ParticipantDisqualifyDemographic'], { relativeTo: this.route, skipLocationChange: true });
      this.qualifyFlag = true;
    }
    else {
      this.showDemo = false;
      this.showDesclosure = false;
      this.showScreener = false;
      this.getDisclosure();
      this.Disclosure();

    }
  }
  getDisclosure(): void {
    this.participateservice.getDisclosure().subscribe(resp => {
      this.questionModel = resp;
    });
  }
  formatDate() {
    var longdate = this.participant.dob;
    var date = new Date(longdate);
    var day = date.getDate();
    var mon = date.getMonth() + 1;
    var yea = date.getFullYear();
    var shortdate = mon + '/' + day + '/' + yea;
    this.participant.dob = shortdate;
  }
  validateDate() {
    var dobDate = new Date(this.participant.dob);
    if ((dobDate) < this.minDate || dobDate > this.maxDate) {
      this.dobChecked = false;
    }
    else {
      this.dobChecked = true;
    }
  }
  Demographics(event): void {
    this.enableBtnFlag = false;
    event.preventDefault();
    event.stopPropagation();
    this.formatDate();

    let _participant: participantModel = new participantModel();
    _participant.studyId = this.participant.studyId;
    _participant.firstName = this.participant.firstName;
    _participant.lastName = this.participant.lastName;
    _participant.dob = this.participant.dob;
    _participant.emailId = this.participant.emailId;
    _participant.phone = this.participant.phone;
    _participant.gender = this.participant.gender;
    _participant.height = parseFloat(this.participant.height + "." + this.participant.inches);
    _participant.weight = this.participant.weight;
    _participant.wristCircumference = this.participant.wristCircumference;
    if (this.IsRefered === "true") {
      _participant.isMock = false;
      _participant.adminid = this.UserIdLogged;
    }
    else {
      _participant.isMock = this.isAdmin;
      _participant.adminid = "0";
    }
    _participant.bmi = parseFloat(this.wholeBMI);
    _participant.dynamicvalues = this.ParticipantDynamicModel;
    this.participateservice.postDemographics(_participant).subscribe(resp => {
      this.particpantResponse = JSON.parse(resp);
      if (this.particpantResponse.attemptNumber > 4) {
        alert("You have reached the maximum number of attempts for this study.");
      } else {
        this.navigateCriteria();
        this.showDemo = false;
      }
    });
  }

  Disclosure(): void {
    this.enableBtnFlag = false;
    this.getQuestions();
    this.showDemo = false;
    this.showDesclosure = false;
    this.showScreener = true;

    this.disclosure.participantId = this.particpantResponse.participantId;
    this.disclosure.studyId = Number(this.studyId);
    this.disclosure.disclosurePass = true;
    this.disclosure.Is_Active = true;
    this.disclosure.attemptNumber = this.particpantResponse.attemptNumber;

    if (!this.isAdmin) {
      this.participateservice.postDisclosure(this.disclosure).subscribe(
        resp => {
          resp
        },
        err => { console.log(err) }
      );
    }
  }
  getQuestions() {
    this.participateservice.getQuestions(this.studyId, this.isAdmin, this.IsRefered).subscribe(resp => {
      this.questionModelbackup = resp.sort((a,b) => { return a.questionOrder - b.questionOrder; } );
      this.questionModelbackupl = this.questionModelbackup.length;
      this.answersl = this.questionModelbackup[this.index].answers.length;
    });
  }
  Screener() {
    this.enableQABtnFlag = false;
    this.questionModelbackupl = this.questionModelbackup.length;

    if (this.questionModelbackupl) {
      this.pRModel.participantId = this.particpantResponse.participantId;
      this.pRModel.attemptNumber = this.particpantResponse.attemptNumber;
      this.pRModel.questionOrder = this.index + 1;

      if (this.questionModelbackup[this.index].answerType === "Radio") {
        this.pRModel.questionId = Number(this.questionModelbackup[this.index].questionDesc.split('|')[0]);
        this.pRModel.answerId = Number(this.questionModelbackup[this.index].
          questionDesc.split('|')[1]);
        this.pRModel.answerText = this.questionModelbackup[this.index].questionDesc.split('|')[2];
      } else if (this.questionModelbackup[this.index].answerType === "Text") {
        this.pRModel.questionId = this.questionModelbackup[this.index].questionId;
        this.pRModel.answerText = this.questionModelbackup[this.index].freeText;
        this.pRModel.answerId = Number(this.questionModelbackup[this.index].answers[0].answerId);
      }
      else {
        this.pRModel.questionId = this.questionModelbackup[this.index].questionId;
        this.pRModel.answerText = this.questionModelbackup[this.index].questionDesc.split('|')[2];
        this.pRModel.answerId = Number(this.questionModelbackup[this.index].answers[0].answerId);
      }
      this.pRModel.studyId = Number(this.studyId);
      this.pRModel.freeText = this.questionModelbackup[this.index].freeText;
      this.pRModel.questionCategory = "PreScreener";
      if (this.pRModel.answerText !== undefined && this.pRModel.answerText.trim() !== '') {
        if (this.isAdmin === true && this.IsRefered === "false") {
          this.participateservice.postmockPreScreenerQA(this.pRModel).subscribe(resp => {

            this.pRSQuestion = JSON.parse(resp);
            if (this.pRSQuestion.participantResult) {
              this.index += 1;

              if (this.index === this.questionModelbackupl) {
                sessionStorage.setItem("participantId", this.pRModel.participantId.toString());
                sessionStorage.setItem("attemptNumber", this.pRModel.attemptNumber.toString())
                this.router.navigate(['ParticipantQualify'], { relativeTo: this.route, skipLocationChange: true });
                this.showScreener = false;
                this.qualifyFlag = true;
              }
            }
            else if (!this.pRSQuestion.participantResult && this.pRSQuestion.questionType === 'PreScreener') {
              this.router.navigate(['ParticipantDisqualify'], { relativeTo: this.route, skipLocationChange: true });
              this.showScreener = false;
              this.qualifyFlag = true;
            }
          });
        }
        else {
          this.participateservice.postPreScreenerQA(this.pRModel).subscribe(resp => {
            this.pRSQuestion = JSON.parse(resp);
            if (this.pRSQuestion.participantResult) {
              this.index += 1;

              if (this.index === this.questionModelbackupl) {
                sessionStorage.setItem("participantId", this.pRModel.participantId.toString());
                sessionStorage.setItem("attemptNumber", this.pRModel.attemptNumber.toString())
                this.router.navigate(['ParticipantQualify'], { relativeTo: this.route, skipLocationChange: true });
                this.showScreener = false;
                this.qualifyFlag = true;
              }
            }
            else {
              this.router.navigate(['ParticipantDisqualify'], { relativeTo: this.route, skipLocationChange: true });
              this.showScreener = false;
              this.qualifyFlag = true;
            }
          });
        }
      }
      this.showDemo = false;
      this.showDesclosure = false;
      this.showScreener = true;
    }

  }
  onChangefd(event, quesId) {
    if (event) {
      this.checkedDisclosure.push(quesId);
    }
    else {
      let index = this.checkedDisclosure.findIndex(x => x === quesId);

      if (index >= 0) {
        this.checkedDisclosure.splice(index, 1);
      }
    }
    this.enableBtnFlag = (this.checkedDisclosure.length === this.questionModel.length);
  }

  charOnly(event) {
    return (event.charCode > 64 &&
      event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
  }
  numberOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;

    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
  numberAndHypen(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;

    return !(charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45);
  }

  formatPhoneNumber(event: Event): void {
    let input = event.target as HTMLInputElement;
    let numbers = input.value.replace(/\D/g, '');
    if (numbers.length > 10) {
      numbers = numbers.substring(0, 10);
    }
    let parts = [];
    if (numbers.length > 3) {
      parts.push(numbers.substring(0, 3));
      if (numbers.length > 6) {
        parts.push(numbers.substring(3, 6));
        parts.push(numbers.substring(6, 10));
      } else {
        parts.push(numbers.substring(3));
      }
    } else {
      parts.push(numbers);
    }
    this.participant.phone = parts.join('-');
    input.value = this.participant.phone;
  }

  limitInputLength(event: Event, maxLength: number) {
    let element = event.target as HTMLInputElement;
    if (element.value.length > maxLength) { element.value = element.value.slice(0, maxLength); }
  }

  numberwithdecimalOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onBlurMethod(event) {
    this.dobChecked = true;
    if (event.length !== 10 && event.length !== 8 && event.length !== 9 && event.length !== 0) {
      this.dobChecked = false;
    }
    let date = event.split('/');

    for (let i = 0; i < date.length; i++) {

      if (i === 0 && (isNaN(Number(date[i])) || Number(date[i]) === 0 || Number(date[i]) > 12)) {
        this.dobChecked = false;
        break;
      }
      if (i === 1 && (isNaN(Number(date[i])) || Number(date[i]) === 0 || Number(date[i]) > 31)) {
        this.dobChecked = false;
        break;
      }
      if (i === 2 && (isNaN(Number(date[i])) || Number(date[i]) === 0 || Number(date[i]) < 1900 || Number(date[i]) > this.maxDate.getFullYear())) {
        this.dobChecked = false;
      }
      if (i === 2 && Number(date[2] === this.maxDate.getFullYear())) {
        if (Number(date[0]) > this.maxDate.getMonth() + 1) {
          this.dobChecked = false;
          break;
        }
        else if (Number(date[1]) > this.maxDate.getDate()) {
          this.dobChecked = false;
          break;
        }
      }
    }
  }
}
