import { Component, OnInit } from '@angular/core';
import { configModel } from 'src/models/config.model';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  
  ck = new configModel();
  hcontact = this.ck.getConfigkeyname('Header_Contact');
  public webRoot: string;
  constructor() { }

  ngOnInit() {
    this.webRoot = AppConfig.appsettings.webRoot;
  }
}
