import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-pre-screening-question',
  templateUrl: './new-pre-screening-question.component.html',
  styleUrls: ['./new-pre-screening-question.component.css']
})
export class NewPreScreeningQuestionComponent implements OnInit {
IsEdit:boolean;
// model = new study('abc');
 name:string;
//study:study;
//study = new study();
  constructor() {
    this.IsEdit=false;
   }

  ngOnInit() {
  //this.name;
  }
  test():void{

    //console.log('test calling: '+this.name);
  }

}
export class study{
  name:string;
}
// new study();