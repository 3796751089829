import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { studyModel } from 'src/models/study.model';
import { allQuestionsModel } from 'src/app/model/prescreeningquetion';
import { tags } from 'src/app/model/prescreeningquetion';
import { participantModel } from 'src/models/participant.model';
import { questionModel, bindingModel, questions, mapQuestionStudy } from 'src/models/question.model';
import { questionstudyModel } from 'src/models/questionstudy.model';
import { AppConfig } from 'src/app/app.config';
import { questionStratification, StraAdditional } from 'src/app/model/demographics.model';
import { participantDetails } from 'src/app/model/participant.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  getUserId() {
    return this.http.get(AppConfig.appsettings.apiRoot + '/Userid')
      .pipe(catchError(this.handleError));
  }

  getAllStudies(): Observable<studyModel[]> {
    return this.http.get<studyModel[]>(AppConfig.appsettings.apiRoot + '/studies')
      .pipe(catchError(this.handleError));
  }
  getAllStudyNames(): Observable<studyModel[]> {
    return this.http.get<studyModel[]>(AppConfig.appsettings.apiRoot + '/studylist')
      .pipe(catchError(this.handleError));
  }

  //Get Study List (Status)
  getStudyList(status: string): Observable<studyModel[]> {
    return this.http.get<studyModel[]>(AppConfig.appsettings.apiRoot + '/study', {
      params: new HttpParams().set("status", status)
    }).pipe(catchError(this.handleError));
  }

  //Get Study List (Study ID)
  getStudyDetails(studyId: number): Observable<studyModel> {
    return this.http.get<studyModel>(AppConfig.appsettings.apiRoot + '/study', {
      params: new HttpParams().set("id", studyId.toString())
    }).pipe(catchError(this.handleError));
  }


  getParticipantResult(ParticipantId: number): Observable<participantModel[]> {
    // Initialize Params Object
    return this.http.get<participantModel[]>(AppConfig.appsettings.apiRoot + '/participantresult', {
      params: new HttpParams().set("ParticipantId", ParticipantId.toString())
    }).pipe(catchError(this.handleError));
  }
  
  postStudy(_studyModel: studyModel): Observable<string> {    
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/study', _studyModel)
      .pipe(catchError(this.handleError));
  }
  updateStudy(_studyModel: studyModel): Observable<studyModel> {
    return this.http.put<studyModel>(AppConfig.appsettings.apiRoot + '/study', _studyModel)
      .pipe(catchError(this.handleError));
  }
  updateStudyWithFlag(_studyModel: studyModel): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/tempdataupdation', _studyModel)
      .pipe(catchError(this.handleError));
  }

  

  deleteStudy(studyId: number): Observable<{}> {
    return this.http.delete(AppConfig.appsettings.apiRoot + '/study/' + studyId)
      .pipe(catchError(this.handleError));
  }

  /* ----------------- Question ------------------------------------*/
  getAllQuestions(): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/question')
      .pipe(catchError(this.handleError));
  }

  getDemographicDynamicValues(studyId:number): Observable<StraAdditional[]> {
    return this.http.get<StraAdditional[]>(AppConfig.appsettings.apiRoot + '/getDynamicValues', {
      params: new HttpParams()
        .set("studyId", studyId.toString())
    }).pipe(catchError(this.handleError));
  }

  getQuestionById(qId: number): Observable<questionModel> {
    return this.http.get<questionModel>(AppConfig.appsettings.apiRoot + '/question/' + qId)
      .pipe(catchError(this.handleError));
  }


  //Get demographic questions (Study ID -optional) DemographicQuestions
  getQuestionsbyCategory(studyCategory: string): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/question', {
      params: new HttpParams()
        .set("Category", studyCategory)
    }).pipe(catchError(this.handleError));
  }

  getStudyDetailsForParticipant(studyId: number): Observable<studyModel> {
    return this.http.get<studyModel>(AppConfig.appsettings.apiRoot + '/Studiesforparticipants', {
      params: new HttpParams()
        .set("id", studyId.toString())
    }).pipe(catchError(this.handleError));
  }

  getQSDemographics(studyId?: number,Adminid:boolean=true,Isrefered:string="false"): Observable<bindingModel[]> {
    return this.http.get<bindingModel[]>(AppConfig.appsettings.apiRoot + '/questionstudydemo', {
      params: new HttpParams()
        .set("studyid", studyId.toString())
        .set("AdminId", Adminid.toString())
        .set("IsRefered", Isrefered)
    }).pipe(catchError(this.handleError));
  }

  getQuestionsStudy(qCategory: string, studyId?: number): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/questionstudy', {
      params: new HttpParams()
        .set("questioncategory", qCategory)
        .set("studyid", studyId.toString())
    }).pipe(catchError(this.handleError));  
  }

  getQuestionStudyAnswers(qCategory: string, questionId?: number, studyId?: number):
    Observable<questionstudyModel> {
    return this.http.get<questionstudyModel>(AppConfig.appsettings.apiRoot + '/questionstudyanswers', {
      params: new HttpParams()
        .set("questioncategory", qCategory)
        .set("studyId", studyId.toString())
        .set("questionId", questionId.toString())
    }).pipe(catchError(this.handleError));
  }

  getQSAforDemographics(qCategory: string, studyId?: number):
    Observable<questionstudyModel[]> {
    return this.http.get<questionstudyModel[]>(AppConfig.appsettings.apiRoot + '/questionstudyanswers', {
      params: new HttpParams()
        .set("questionCategory", qCategory)
        .set("studyId", studyId.toString())
    }).pipe(catchError(this.handleError));
  }

  // api/alltags/
  getAllTagsForAdmin(): Observable<tags[]> {
    return this.http.get<tags[]>(AppConfig.appsettings.apiRoot + '/taglist')
      .pipe(catchError(this.handleError));
  }
  //getmastervaluesforcombo
  getMasterValuesforCombo(): Observable<any[]> {
    return this.http.get<any[]>(AppConfig.appsettings.apiRoot + '/getmastervalue')
      .pipe(catchError(this.handleError));
  }
  // api/questions/
  getAllQuestionsForAdmin(): Observable<allQuestionsModel[]> {
    return this.http.get<allQuestionsModel[]>(AppConfig.appsettings.apiRoot + '/questions')
      .pipe(catchError(this.handleError));
  }

  // api/updatemultiplequestionstudy/
  updateAllQuestionsForAdmin(_questionModel: questionstudyModel[]): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/updatemultiplequestionstudy', _questionModel)
      .pipe(catchError(this.handleError));
  }

  getQuestionsForAdmin(studyId: number, qCategory: string): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/questionstudy', {
      params: new HttpParams()
        .set("questioncategory", qCategory)
        .set('studyId', studyId.toString())
    }).pipe(catchError(this.handleError));
  }

  // api/question/
  postQuestion(_questionModel: questionModel): Observable<questionModel> {
    return this.http.post<questionModel>(AppConfig.appsettings.apiRoot + '/question', _questionModel)
      .pipe(catchError(this.handleError));
  }

  // api/questionstudy
  postQuestionStudy(_qsModel: questionstudyModel): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/questionstudy', _qsModel)
      .pipe(catchError(this.handleError));
  }

  // api/questionstudy updatequestionstudy 
  postUpdateQS(_qsModel: questionstudyModel): Observable<questionstudyModel> {
    return this.http.post<questionstudyModel>(AppConfig.appsettings.apiRoot + '/updatequestionstudy', _qsModel)
      .pipe(catchError(this.handleError));
  }

  postUpdateDynamicStratification(_AdditionalModel: StraAdditional): Observable<StraAdditional> {
    return this.http.post<StraAdditional>(AppConfig.appsettings.apiRoot + '/updateDynamicFields', _AdditionalModel)
      .pipe(catchError(this.handleError));
  }

  updateQuestion(_questionModel: questionModel): Observable<questionModel> {
    return this.http.put<questionModel>(AppConfig.appsettings.apiRoot + '/question', _questionModel)
      .pipe(catchError(this.handleError));
  }

  // api/question/questionId
  deleteQuestion(qId: number): Observable<{}> {
    return this.http.delete(AppConfig.appsettings.apiRoot + '/question/' + qId)
      .pipe(catchError(this.handleError));
  }

  deleteQS(qId: number, sId: number): Observable<{}> {
    return this.http.delete(AppConfig.appsettings.apiRoot + '/deletequestionstudy', {
      params: new HttpParams()
        .set("questionId", qId.toString())
        .set("studyId", sId.toString())
    }).pipe(catchError(this.handleError));
  }

  deleteQuestionStratification(qId: number, sId: number, groupId: number): Observable<{}> {
    return this.http.delete(AppConfig.appsettings.apiRoot + '/deletequestionstratification', {
      params: new HttpParams()
        .set("questionId", qId.toString())
        .set("studyId", sId.toString())
        .set("groupId", groupId.toString())
    }).pipe(catchError(this.handleError));
  }
  deleteStratification(sId: number, groupId: number): Observable<{}> {
    return this.http.delete(AppConfig.appsettings.apiRoot + '/deletestratification', {
      params: new HttpParams()
        .set("studyId", sId.toString())
        .set("groupId", groupId.toString())
    }).pipe(catchError(this.handleError));
  }
  deleteDynamicStratification(sId: number, groupId: number ,itemName : string): Observable<{}> {
    return this.http.delete(AppConfig.appsettings.apiRoot + '/deleteDynamicstratification', {
      params: new HttpParams()
        .set("studyId", sId.toString())
        .set("groupId", groupId?.toString() ?? '-1')
        .set("itemName", itemName)
    }).pipe(catchError(this.handleError));
  }
  getStratificationQuestions(): Observable<questionStratification[]> {
    return this.http.get<questionStratification[]>(AppConfig.appsettings.apiRoot + '/stratificationQuestions')
      .pipe(catchError(this.handleError));
  }
  /* --------------------------------------------------------------- */

  /* -----------------------Admin Participant view------------------ */
  getParticipantAttempts(pId: number, sId: number):
    Observable<any> {
    return this.http.get<any>(AppConfig.appsettings.apiRoot + '/participantattempt', {
      params: new HttpParams()
        .set("participId", pId.toString())
        .set("studyId", sId.toString())
    }).pipe(catchError(this.handleError));
  }
  getParticipantDetails(pId: number, sId: number):
    Observable<participantDetails> {
    return this.http.get<participantDetails>(AppConfig.appsettings.apiRoot + '/participantdetails', {
      params: new HttpParams()
        .set("participantId", pId.toString())
        .set("studyId", sId.toString())
    }).pipe(catchError(this.handleError));
  }  
  postUpdateParticipantStatus(_participantDetails: participantDetails): Observable<any> {
    return this.http.post<any>(AppConfig.appsettings.apiRoot + '/updateparticipantstatus', _participantDetails)
      .pipe(catchError(this.handleError));
  }
  getQuestionsbyStudyandTag(studyId: number): Observable<questions[]> {
    return this.http.get<questions[]>(AppConfig.appsettings.apiRoot + '/questions', {
      params: new HttpParams()
        .set("studyId", studyId.toString())
    }).pipe(catchError(this.handleError));
  }


  getQuestionsbyReference(Question_id: number): Observable<number> {
    return this.http.get<number>(AppConfig.appsettings.apiRoot + '/questionReference', {
      params: new HttpParams()
        .set("Question_id", Question_id.toString())
    }).pipe(catchError(this.handleError));
  }

  mapQuestionstoStudy(_mapQuestionStudy: mapQuestionStudy): Observable<any> {
    return this.http.post<any>(AppConfig.appsettings.apiRoot + '/mapQuestionstoStudy', _mapQuestionStudy)
      .pipe(catchError(this.handleError));
  }

  getQuestionsAnswerExport(studyName: string): Observable<any[]> {
    return this.http.get<any[]>(AppConfig.appsettings.apiRoot + '/studyQuestionAnswers', {
      params: new HttpParams()
        .set("studyName", studyName.toString())
    }).pipe(catchError(this.handleError));
  }

  deleteQuestions(questionsForDelete: number[]): Observable<{}> {
    return this.http.post(AppConfig.appsettings.apiRoot + '/deleteQuestions', questionsForDelete)
      .pipe(catchError(this.handleError));
  }
  /* --------------------------------------------------------------- */

  handleError(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }
}
