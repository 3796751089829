// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-with-content {
  color: #ffffff;
  width: 100%;
}

.shareWithFriendWrap {
  padding: 0;
}

.viewGuidelines {
  font-size: 16px;
}

.viewGuidelines:hover {
  color: #ffffff;
}

.share-buttons {
  padding: 10px 0 !important;
}

a[name=message] {
  display: none;
}

.share-button, a[name=share-button] {
  border-radius: 3px;
  margin: .3em;
  background-color: #fff;
  color: #43B02A;
  border: none;
  padding: 3px 8px;
}

a[name=facebook] {
  padding: 3px 12px !important;
}

/* Show the SMS button and apply mobile-specific styles on smaller screens */
@media (max-width: 768px) {
  a[name=message] {
    display: inline-block;
  }

  a[name=share-button] {
    font-size: 15px;
    width: 10%;
    padding: 8px 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/participant/participant_refer/participant_refer.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,cAAc;EACd,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA,4EAA4E;AAC5E;EACE;IACE,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,UAAU;IACV,iBAAiB;EACnB;AACF","sourcesContent":[".share-with-content {\r\n  color: #ffffff;\r\n  width: 100%;\r\n}\r\n\r\n.shareWithFriendWrap {\r\n  padding: 0;\r\n}\r\n\r\n.viewGuidelines {\r\n  font-size: 16px;\r\n}\r\n\r\n.viewGuidelines:hover {\r\n  color: #ffffff;\r\n}\r\n\r\n.share-buttons {\r\n  padding: 10px 0 !important;\r\n}\r\n\r\na[name=message] {\r\n  display: none;\r\n}\r\n\r\n.share-button, a[name=share-button] {\r\n  border-radius: 3px;\r\n  margin: .3em;\r\n  background-color: #fff;\r\n  color: #43B02A;\r\n  border: none;\r\n  padding: 3px 8px;\r\n}\r\n\r\na[name=facebook] {\r\n  padding: 3px 12px !important;\r\n}\r\n\r\n/* Show the SMS button and apply mobile-specific styles on smaller screens */\r\n@media (max-width: 768px) {\r\n  a[name=message] {\r\n    display: inline-block;\r\n  }\r\n\r\n  a[name=share-button] {\r\n    font-size: 15px;\r\n    width: 10%;\r\n    padding: 8px 14px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
