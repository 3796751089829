import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {
  static appsettings: AppSettings;
}

export class AppSettings {
  apiRoot: string;
  webRoot: string;
  gtmcode: string;
  token: string;
  apiUrl: string;
}
