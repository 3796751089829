import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participant-study-demographics',
  templateUrl: './participant_study_demographics.component.html',
  styleUrls: ['./participant_study_demographics.component.css']
})
export class ParticipantStudyDemographicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
