// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BonusText {
    font-size: 14px;
    background: #f4f4f4;
    padding: 20px 0;
}
.list-none {
    list-style: none;
}
.pointHead {
    margin-top: 20px;
}

ol li {
    padding: 5px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/participant/participant-bonus/participant-bonus.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".BonusText {\r\n    font-size: 14px;\r\n    background: #f4f4f4;\r\n    padding: 20px 0;\r\n}\r\n.list-none {\r\n    list-style: none;\r\n}\r\n.pointHead {\r\n    margin-top: 20px;\r\n}\r\n\r\nol li {\r\n    padding: 5px 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
