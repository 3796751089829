import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';

@Component({
  selector: 'app-participantdisqualify',
  templateUrl: './participant_disqualify.component.html',
  styleUrls: ['./participant_disqualify.component.css']
})
export class ParticipantDisqualifyComponent implements OnInit {
  participantDisqualifyMsg: string;
  isAdmin: boolean;
  studyId: string;

  constructor(private router: Router, private configService: ConfigService, private authGaurd: AuthGaurdService, private route: ActivatedRoute,) { }

  @ViewChild('hiddenInput', {static: true}) hiddenField: ElementRef;

  ngOnInit() {
    this.hiddenField.nativeElement.focus();
    this.isAdmin = this.authGaurd.isAdmin.toLowerCase() == "true" ? true : false;
    this.studyId = this.route.snapshot.params["studyid"];

    this.configService.getAppConfigByKey('Participant_Disqualify_Message').subscribe(response => {
      this.participantDisqualifyMsg = response.valText;
    });
  }
  
  callParticipantHome(): void {
    this.router.navigateByUrl('');
  }
  callAdminHome(): void {
    this.router.navigate(['/adminhome/studydetail/', this.studyId], { fragment: 'display', skipLocationChange: true });
  }
}
