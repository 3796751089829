import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService implements CanActivate {
  isAdmin: string;
  constructor(private _configService: ConfigService, private route: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.isAdmin.toLowerCase() == 'true') {
      return true;
    }
    else {
      this.route.navigate(['']);
      return false;
    }

    //return this._configService.getMVCValidUser()
    //  .pipe(
    //    map(data => {
    //      // console.log(data);
    //      if (data.toLowerCase() == 'true') {
    //        return true;
    //      }
    //      else {
    //        this.route.navigate(['']);
    //        return false;
    //      }
    //    }));
  }
}
