import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ParticipantQualifyComponent } from './participant/participant_qualify/participant_qualify.component';
import { ParticipantDisqualifyComponent } from './participant/participant_disqualify/participant_disqualify.component';
import { ParticipantDisqualifyDemographicComponent } from './participant/participant-disqualify-demographic/participant-disqualify-demographic.component';
import { ParticipantReferComponent } from './participant/participant_refer/participant_refer.component';
import { ParticipantContactusComponent } from './participant/participant_contactus/participant_contactus.component';
import { ParticipantHomeComponent } from './participant/participant_home/participant_home.component';
import { ParticipantStudyDetailComponent } from './participant/participant_study_detail/participant_study_detail.component';
import { ParticipantStudyDemographicsComponent } from './participant/participant_study_demographics/participant_study_demographics.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ParticipantBonusComponent } from './participant/participant-bonus/participant-bonus.component';
import { ParticipantStudyNotFoundComponent } from './participant/participant-study-not-found/participant-study-not-found.component';
import { ParticipantLayoutComponent } from './_layout/participant-layout/participant-layout.component';
import { AdminLayoutComponent } from './_layout/admin-layout/admin-layout.component';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';

const routes: Routes = [
  {
    path: '', component: ParticipantLayoutComponent,
    children: [
      {
        path: '', component: ParticipantHomeComponent, 
      },
      {
        path: 'ParticipantStudyDetail/:studyid', component: ParticipantStudyDetailComponent,
        children: [
          {
            path: '', component: ParticipantDisqualifyComponent
          },
          {
            path: 'ParticipantDisqualify', component: ParticipantDisqualifyComponent
          },
          {
            path: 'ParticipantQualify', component: ParticipantQualifyComponent
          },
          {
            path: 'ParticipantDisqualifyDemographic', component: ParticipantDisqualifyDemographicComponent
          }
        ]
      },
      {
        path: 'ParticipantStudyDetail/:studyid/study-not-found', component: ParticipantStudyNotFoundComponent
      },
      {
        path: 'ParticipantStudyDemographics', component: ParticipantStudyDemographicsComponent
      },
      {
        path: 'participantrefer', component: ParticipantReferComponent
      },
      {
        path: 'contactus', component: ParticipantContactusComponent
      },
      {
        path: 'ParticipantBonus', component: ParticipantBonusComponent
      }
    ]
  },

  {
    path: 'adminhome', component: AdminLayoutComponent,
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
    , canActivate: [AuthGaurdService]
  },
  {
    path: 'participant',
    loadChildren: () => import('./participant/participant.module').then(mod => mod.ParticipantModule)
  },
];

@NgModule({
  imports: [HttpClientModule, AngularSvgIconModule,
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule, ReactiveFormsModule, FormsModule]
})
export class AppRoutingModule {
}
// {
//   paramsInheritanceStrategy: 'always',
//   useHash: true
// }
