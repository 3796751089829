import { Component, OnInit } from '@angular/core';
import { configModel } from 'src/models/config.model';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-participant-header',
  templateUrl: './participant-header.component.html',
  styleUrls: ['./participant-header.component.css']
})
export class ParticipantHeaderComponent implements OnInit {

  configModel: configModel;
  hcontact: string;
  isAdminUser = false;
  
  constructor(private configService: ConfigService) { 
    this.isAdminUser = localStorage.getItem('isAdminUser') === 'true';
  }

  ngOnInit() {
    this.configService.getAppConfig()
      .subscribe(
        (resp: configModel) => {
        this.configModel = resp
        localStorage.setItem('config', JSON.stringify(resp));
        let ck = new configModel();
        this.hcontact = ck.getConfigkeyname('Header_Contact');
      });
  }

  redirectToAdmin(event: Event) {
    event.preventDefault();
    const currentUrl = window.location.href.split('#')[0];
    window.location.href = `${currentUrl}admin`;
  }
}
