import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // throw new Error("Method not implemented.");
    return next.handle(req).pipe(catchError(err => {
      
      //if (err.status === 401) {
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          // location.reload(true);
          
      //}

      //const error = err.error.message || err.statusText;
      return throwError(err);
  }))
  }


}
