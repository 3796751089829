// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cSec {
    margin-bottom: 35px;
}
.cTitle {
    font-size: 20px;
}
.cVal {
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/participant/participant_contactus/participant_contactus.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":[".cSec {\r\n    margin-bottom: 35px;\r\n}\r\n.cTitle {\r\n    font-size: 20px;\r\n}\r\n.cVal {\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
