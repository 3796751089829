import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ParticipantService } from 'src/services/participant.service';
import { questionModel, answers, answersQualify } from 'src/models/question.model';
import { studyModel } from 'src/models/study.model';
import { participantModel, participantResponseModel, particpantResponse } from 'src/models/participant.model';
import { ConfigService } from 'src/services/config.service';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';

@Component({
  selector: 'app-participantqualify',
  templateUrl: './participant_qualify.component.html',
  styleUrls: ['./participant_qualify.component.css']
})
export class ParticipantQualifyComponent implements OnInit, AfterViewInit {

  qualifyQuesionModel: questionModel[];
  questionCount: number;
  qualifyStudyId: string;
  qualifyQuestion: questionModel[];
  answersLength: number;
  index: number = 0;
  studyModel: studyModel;
  participant = new participantModel();
  particpantResponse = new particpantResponse();
  qualifyModel = new participantResponseModel();
  availableTimeFlag: boolean = true;
  finalThankyouFlag: boolean = false;
  setAppointmentFlag: boolean = false;
  selectedanswers: answers[] = [];
  newAnswerId: number;
  newQuesId: number;
  participantId:string;
  attemptNumber: string;
  thankyouMsg: string;
  besttimetocallMsg: string;
  isAdmin: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private participateservice: ParticipantService, private configService: ConfigService, private authGaurd: AuthGaurdService) { }

  @ViewChild('hiddenInputNew', {static: false}) hiddenFieldNew: ElementRef;

  ngOnInit() {
    this.isAdmin = this.authGaurd.isAdmin.toLowerCase() === "true";
    this.participantId=sessionStorage.getItem("participantId");
    this.attemptNumber=sessionStorage.getItem("attemptNumber");
    sessionStorage.clear();
    this.qualifyStudyId = this.route.snapshot.params["studyid"];
    
    this.configService.getAppConfigByKey('Participant_Qualify_Message').subscribe(response => {
      this.thankyouMsg = response.valText;
    });

    this.configService.getAppConfigByKey('Participant_BestTimetoCall_Message').subscribe(response => {
      this.besttimetocallMsg = response.valText;
    });
    this.participateservice.getStudyDetail(this.qualifyStudyId).subscribe(resp => {
      this.studyModel = resp;
    });
    this.participant.studyId = this.qualifyStudyId;    
    this.getQuestions();    
  }

  

  ngAfterViewInit(): void {
    this.hiddenFieldNew.nativeElement.focus();
  }

  getQuestions() {
    this.participateservice.getGeneralQuestions(this.participant.studyId).subscribe(resp => {
      this.qualifyQuesionModel = resp;
      this.questionCount = this.qualifyQuesionModel.length;
      this.answersLength = this.qualifyQuesionModel[this.index].answers.length;
      this.qualifyQuestion = this.qualifyQuesionModel;
    });
  }
  onChangeCategory(event, ansId, anstext, quesId) {
    if (event) {
      let obj: answersQualify = new answersQualify();
      obj.answerId = ansId;
      obj.answerText = anstext;
      obj.questionId = quesId;
      this.newAnswerId = obj.answerId;
      this.newQuesId = obj.questionId;
      this.selectedanswers.push(obj);
    }
    else {
      let index = this.selectedanswers.findIndex(x => x.answerId === ansId);

      if (index >= 0) {
        this.selectedanswers.splice(index, 1);
      }
    }
    if(this.selectedanswers.length >= 1) {
      this.setAppointmentFlag = true;
    } else {
      this.setAppointmentFlag = false;
    }
  }

  setAppointment() {
    if (this.questionCount) {
      this.qualifyModel.participantId = +this.participantId;
      this.qualifyModel.attemptNumber = +this.attemptNumber;
      this.qualifyModel.studyId = Number(this.qualifyStudyId);      
      
      let ansString = [];
      this.selectedanswers.forEach(
        x => {
          ansString.push(x.answerText, x.answerId);
        }
      );

      this.qualifyModel.answerText = ansString.join(',');
      this.qualifyModel.answerId = this.newAnswerId;
      this.qualifyModel.questionCategory = "general";
      this.qualifyModel.questionId = this.newQuesId;
      this.qualifyModel.questionOrder = 1;
      console.log('Inside best time to call');
      this.participateservice.postGeneralrQA(this.qualifyModel).subscribe(
        response => console.log("Post qualify question API response", response)
      );
      this.availableTimeFlag = false;
      this.finalThankyouFlag = true;
    }
  }

  callParticipantHome(): void {
    this.router.navigateByUrl('');
  }
  callAdminHome(): void {    
    this.router.navigate(['/adminhome/studydetail/', this.qualifyStudyId], { fragment: 'display', skipLocationChange: true });
  }
}
