import { Component, OnInit } from '@angular/core';
import { configModel, config } from 'src/models/config.model';
import { ParticipantService } from 'src/services/participant.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './participant_contactus.component.html',
  styleUrls: ['./participant_contactus.component.css']
})
export class ParticipantContactusComponent implements OnInit {

  lat: number;
  lng: number;
  phone: string;
  fax: string;
  email: string;
  address: string;

  constructor(private participantService: ParticipantService) {
    this.lat = 38.921420;
    this.lng = -94.661620;
  }

  ngOnInit() {
    let ck = new configModel();
    this.phone = ck.getConfigkeyname('Contact_Us_Phone');
    this.fax = ck.getConfigkeyname('Contact_Us_Fax');
    this.email = ck.getConfigkeyname('Contact_Us_Email');
    this.address = ck.getConfigkeyname('Contact_Us_Address');

  }
}