// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-light, .bg-dark
{
    background-image: linear-gradient(to right, #250056 20%, #0076AE 48%, #0076AE 52%, #250056 100%);
}
.nav-link,
.navbar-light .navbar-nav .nav-link,
.nav-link.active,
.navbar-light .navbar-brand
{
    color: #fff;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link
{
    color: #fff;
}

agm-map {
    height: 300px;
  }`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;;IAEI,gGAAgG;AACpG;AACA;;;;;IAKI,WAAW;AACf;AACA;;IAEI,WAAW;AACf;;AAEA;IACI,aAAa;EACf","sourcesContent":[".bg-light, .bg-dark\r\n{\r\n    background-image: linear-gradient(to right, #250056 20%, #0076AE 48%, #0076AE 52%, #250056 100%);\r\n}\r\n.nav-link,\r\n.navbar-light .navbar-nav .nav-link,\r\n.nav-link.active,\r\n.navbar-light .navbar-brand\r\n{\r\n    color: #fff;\r\n}\r\n.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link\r\n{\r\n    color: #fff;\r\n}\r\n\r\nagm-map {\r\n    height: 300px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
