import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participant_study_screener',
  templateUrl: './participant_study_screener.component.html',
  styleUrls: ['./participant_study_screener.component.css']
})
export class ParticipantStudyScreenerComponent implements OnInit {

  answer:string;
  
  constructor() { }

  ngOnInit() {
  }
  saveAnswers():void{
    //console.log('saveAnswers calling...');
    //console.log(this.answer);
  }

}
