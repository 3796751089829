export class studyModel {
  constructor() {
  }
  studyId: number;
  studyName: string;
  isPublished: boolean = false;
  studyMaxtime: number;
  studySummary: string;
  studyCompensation: number;
  studyAge: string;
  studyAgeMin: string;
  studyAgeMax: string;
  studyGender: string;
  studyGenderMale: boolean;
  studyGenderFemale: boolean;
  studyGenderBoth: string;
  // studywristcirc: string;
  studyWristCircumference: string;
  studyBasics: string;
  studyCriteria: string;
  studyCommitment: string;
  // studyScreening: string;//studyScreeningModel;
  studyScreeningDays: string;
  studyScreeningTime: string;
  studySchedule: string;//studyScheduleModel[];
  studyReferral: string;
  studyRequirements: string;
  studyBMI: string;
  studyBMIMin: string;
  studyBMIMax: string;
  studyHeight: string;
  studyWeight: string;
  studyWeightMin: string;
  studyWeightMax: string;
  studyTag: string;
  isActive: boolean = true;
  screeningSchedule: string;
  tag: string[];
  IsApproved: boolean;
}
export class screeningScheduleModel {

  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  sno: number;
  screeningscheduleDays: string;
  screeningTimes: string;
  isValidDays: boolean;
  isValidTimes: boolean;
}

export class studyScheduleModel {
  screeningDays: string;
  followupDays: string;
  isValid: boolean;
  //sno: number;
}

export class studyRequirementsModel {
  requirement: string;
}

export class studyCommitmentModel {
  commitmentscreeningDays: string;
  time: string;
  dcd: string;
  description: string;
  followupVisits: string;
  followupTime: string;
}

export class studyResponse {
  studyId: number
}
