import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { configModel, ms_Config } from 'src/models/config.model';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  userId: string;

  constructor(private http: HttpClient, private router: Router) { }

  getAppConfig() {
    return this.http.get<configModel>(AppConfig.appsettings.apiRoot + '/Config')
      .pipe(catchError(this.handleError));
  }

  getAppConfigByKey(key: string) {
    
    return this.http.get<ms_Config>(AppConfig.appsettings.apiRoot + '/Config?key=' + key)
      .pipe(catchError(this.handleError));
  }

  getMVCValidUser() {
    return this.http.get(AppConfig.appsettings.webRoot + 'Home/GetUser', { responseType: 'text' }
    ).pipe(catchError(this.handleError));
  }

  updateAboutUsConfig(key: string, value: string) {
    return this.http.put(AppConfig.appsettings.apiUrl + 'UpdateConfig',  {Key: key, Value: value})
      .pipe(catchError(this.handleError));
  }

  handleError(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }
}
