// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 400px) {
    .col-12 {
        width: 50%;
        max-width: 50%;
    }
}

.text16 {
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/_layout/participant-footer/participant-footer.component.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,cAAc;IAClB;AACJ;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@media (min-width: 400px) {\r\n    .col-12 {\r\n        width: 50%;\r\n        max-width: 50%;\r\n    }\r\n}\r\n\r\n.text16 {\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
