import { Component, OnInit } from '@angular/core';
import { ParticipantService } from 'src/services/participant.service';
import { studyModel, studyRequirementsModel } from 'src/models/study.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-participantstudylist',
  templateUrl: './participant_study_list.component.html',
  styleUrls: ['./participant_study_list.component.css']
})
export class ParticipantStudyListComponent implements OnInit {

  studyModel: studyModel[];
  studyRequirements = new studyModel;
  requirements: any;
  constructor(private participantService: ParticipantService) {
  }

  ngOnInit() {
    this.participantService.getStudyList()
    .pipe(
      map( mapvalue => {
        if(mapvalue && mapvalue.length > 0) {
          mapvalue.forEach(element => {
            element.studyRequirements = JSON.parse(element.studyRequirements);
            element.studyCommitment = JSON.parse(element.studyCommitment);
          });
        }
        return mapvalue;
      } ) 
    )
    .subscribe(resp => {
    this.studyModel = resp;
    //console.log("Reponse of participant",resp);
    //console.log("Reponse of participant", resp[0].studyCommitment);
    //this.studyModel.forEach(study=>
    //{

      //this.requirements = JSON.parse(study.studyRequirements);
      //console.log(this.requirements);
      // this.studyRequirements = [];
      // this.requirements = study.studyRequirements;
      // this.requirements = JSON.parse(this.requirements);
      // if(this.requirements)
      // {
      // this.requirements.forEach(req => 
      //   {
      //     this.studyRequirements.push(req.requirement);
      //   }
      //   )
      //   study.studyRequirements = this.studyRequirements;
      // }
      // else
      // {
      //   study.studyRequirements = [];

      // }
        
      //study.studyRequirements = JSON.parse(study.studyRequirements);
      //console.log(resp);
    //})
        //let arr: studyRequirementsModel[] = JSON.parse(this.studyModel[0].studyRequirements);
      //this.studyRequirements = JSON.parse(resp[0].studyRequirements);
      // console.log(this.studyModel);
      //arr.forEach((value, index) => {
        //let arr = JSON.parse(this.studyModel[index].studyRequirements)
        //console.log(arr);
        //this.studyRequirements.push(value);
        //console.log(this.studyRequirements);
      //});
    });
  }

}
