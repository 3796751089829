import { Component, OnInit, Inject, ElementRef, OnDestroy } from '@angular/core';
import { ConfigService } from 'src/services/config.service';
import { configModel } from 'src/models/config.model';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';
import { Subscription, fromEvent, merge, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  env = environment;
  config: configModel;
  title = 'ngSFC';
  adminHeader: string;
  adminHeaderFlag: boolean = false;
  aboutus: string;
  hcontact: string;
  sessionInactiveTime: number;
  constructor(private configService: ConfigService, @Inject(DOCUMENT) private document: any, private _router: ActivatedRoute, private elementRef: ElementRef, private authGaurd: AuthGaurdService, private _router1: Router) {
    this.authGaurd.isAdmin = this.elementRef.nativeElement.getAttribute('isAdmin');    
    this.sessionInactiveTime = (this.elementRef.nativeElement.getAttribute('allowedSessionInactiveTime')) * 1000;
  }
  router:string;
  
  private userActivityEvents = ['mousemove', 'click', 'touchstart', 'keypress'];
  private userActivitySubscription: Subscription;

  ngOnInit() {
    
    if (this.authGaurd.isAdmin == "true") {
      this._router1.navigate(['adminhome']);
    }
    this.router = location.href;
    this.adminHeader = document.location.href;
    if(this.adminHeader.includes('adminhome')) {      
      this.startUserActivityTimer();
    }
    
    if(this.adminHeader.includes('/#/admin')) {
      this.adminHeaderFlag = true;
    }
    else {
      this.adminHeaderFlag = false;
    }
  }

  ngOnDestroy() {
    this.userActivitySubscription.unsubscribe();
  }

  startUserActivityTimer() {
    this.userActivitySubscription = merge(
      ...this.userActivityEvents.map(event => fromEvent(document, event))
    )
      .pipe(
        switchMap(() => timer(this.sessionInactiveTime))
      )
      .subscribe(() => {
        alert('Session expired due to inactivity.');
        window.location.reload();
      });
  }
}
