import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ControlContainer } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { AppSettings } from 'src/app/model/app-settings';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  public appSettings: AppSettings;
  constructor(public auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // throw new Error("Method not implemented.");
    // console.log(req.url.includes("/Home/GetUser"));
    this.appSettings = AppConfig.appsettings;
    if (this.appSettings != undefined) {

      //if (!req.url.includes("/Home/GetUser")) {
      req = req.clone({
        setHeaders: {
          //ContentType: 'application/json',
          //'Access-Control-Allow-Origin': '*'
          // Authorization: `Bearer ${this.auth.getToken}` 'SFCUser','SFCUser1@3'
          Authorization: "Basic " + this.appSettings.token // + AppConfig.appsettings.token //+ btoa("SFCUser:SFCUser1@3")
        }
      });
    }
    //}    
    return next.handle(req);
  }


}


  
// yes
// cache-control: no-cache
 
// [?7/?25/?2019 6:37 PM]  Ramamoorthy M:  
// ok..ji... do we faced any sucha a issue in our website?
 
// 'Expires': 'Sat, 01 Jan 1900 00:00:00 GMT'
// 'If-Modified-Since': 0
