export class configModel {
    //  key: string;
    // value: string;
    config: config[];

    getConfigkeyname(keyText: string): string {
        let configModel: configModel;
        let keyName: string
        if (localStorage.getItem('config') != null) {
            configModel = JSON.parse(localStorage.getItem('config'));
            configModel.config.map(p => { if (p.keyText === keyText) keyName = p.valText });
            return keyName;
        }
    }
}
export class config {
    configId: number;
    keyText: string;
    valText: string;
}
export class ms_Config {
  configId: number;
  keyText: string;
  valText: string;
}
