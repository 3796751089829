import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ParticipantQualifyComponent } from './participant/participant_qualify/participant_qualify.component';
import { ParticipantDisqualifyComponent } from './participant/participant_disqualify/participant_disqualify.component';
import { ParticipantDisqualifyDemographicComponent } from './participant/participant-disqualify-demographic/participant-disqualify-demographic.component';
import { ParticipantReferComponent } from './participant/participant_refer/participant_refer.component';
import { ParticipantContactusComponent } from './participant/participant_contactus/participant_contactus.component';
import { ParticipantHomeComponent } from './participant/participant_home/participant_home.component';
import { ParticipantStudyListComponent } from './participant/participant_study_list/participant_study_list.component';
import { ParticipantStudyDetailComponent } from './participant/participant_study_detail/participant_study_detail.component';
import { ParticipantStudyDemographicsComponent } from './participant/participant_study_demographics/participant_study_demographics.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewPreScreeningQuestionComponent } from './admin/pre-screening-questions/new-pre-screening-question/new-pre-screening-question.component';
import { ParticipantStudyDisclosureComponent } from './participant/participant-study-disclosure/participant-study-disclosure.component';
import { ParticipantStudyScreenerComponent } from './participant/participant_study_screener/participant_study_screener.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptorService } from 'src/services/token-interceptor.service';
import { ErrorInterceptorService } from 'src/services/error-interceptor.service';
import { ParticipantBonusComponent } from './participant/participant-bonus/participant-bonus.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminHeaderComponent } from './_layout/admin-header/admin-header.component';

import { CookieService } from 'ngx-cookie-service';
import { ParticipantStudyNotFoundComponent } from './participant/participant-study-not-found/participant-study-not-found.component';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';
import { ParticipantHeaderComponent } from './_layout/participant-header/participant-header.component';
import { ParticipantLayoutComponent } from './_layout/participant-layout/participant-layout.component';
import { ParticipantFooterComponent } from './_layout/participant-footer/participant-footer.component';
import { AdminLayoutComponent } from './_layout/admin-layout/admin-layout.component';
import { AppConfig } from './app.config';
import { DataTablesModule } from 'angular-datatables';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PaginatorModule } from 'primeng/paginator';
import { CanDeactivateGuard } from 'src/services/can-deactivate.guard';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

export function initializeApp(appconfig: AppConfig) {
  return () => appconfig;
}
@NgModule({ exports: [
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        PageNotFoundComponent,
        ParticipantQualifyComponent,
        ParticipantDisqualifyComponent,
        ParticipantDisqualifyDemographicComponent,
        ParticipantReferComponent,
        ParticipantContactusComponent,
        ParticipantHomeComponent,
        ParticipantStudyListComponent,
        ParticipantStudyDetailComponent,
        NewPreScreeningQuestionComponent,
        ParticipantStudyDemographicsComponent,
        ParticipantStudyDisclosureComponent,
        ParticipantStudyScreenerComponent,
        ParticipantBonusComponent,
        AdminHeaderComponent,
        ParticipantStudyNotFoundComponent,
        ParticipantHeaderComponent,
        ParticipantLayoutComponent,
        ParticipantFooterComponent,
        AdminLayoutComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDe790bl0yY3XhyMKS4_vnkMFG-PeQ36t4'
        }),
        BsDatepickerModule.forRoot(),
        BrowserAnimationsModule, // (Required) for share counts
        FontAwesomeModule,
        ButtonModule,
        PanelModule,
        //AngularFontAwesomeModule,
        BsDropdownModule.forRoot(),
        TimepickerModule.forRoot(),
        PaginatorModule,
        DataTablesModule,
        CheckboxModule,
        MultiSelectModule
    ], providers: [AuthGaurdService,
        CanDeactivateGuard,
        {
            provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true
        },
        CookieService,
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
  
 }
