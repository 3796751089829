// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alignCenter {
    margin: 0 auto;
}
.availableTime {
    padding: 35px 0 0;
}
.answerOptions .custom-control-label {
    display: inline-block; 
    padding-left: 10px;
}
.availableTime .greenBtnBig {
    max-width: 100%;
    width: auto
}
.hiddenInput {
    opacity: 0;
    height: 0;
    padding: 0;
    border: none;
 
    z-index: 0;
    /* left: 45%;
    top: 90%; */
}`, "",{"version":3,"sources":["webpack://./src/app/participant/participant_qualify/participant_qualify.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf;AACJ;AACA;IACI,UAAU;IACV,SAAS;IACT,UAAU;IACV,YAAY;;IAEZ,UAAU;IACV;eACW;AACf","sourcesContent":[".alignCenter {\r\n    margin: 0 auto;\r\n}\r\n.availableTime {\r\n    padding: 35px 0 0;\r\n}\r\n.answerOptions .custom-control-label {\r\n    display: inline-block; \r\n    padding-left: 10px;\r\n}\r\n.availableTime .greenBtnBig {\r\n    max-width: 100%;\r\n    width: auto\r\n}\r\n.hiddenInput {\r\n    opacity: 0;\r\n    height: 0;\r\n    padding: 0;\r\n    border: none;\r\n \r\n    z-index: 0;\r\n    /* left: 45%;\r\n    top: 90%; */\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
