import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participantrefer',
  templateUrl: './participant_refer.component.html',
  styleUrls: ['./participant_refer.component.css']
})
export class ParticipantReferComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
    window.addEventListener('resize', this.resizeFunction);
    this.resizeFunction();
  }

  resizeFunction() {
    var width = window.innerWidth;
    var shareLink = document.getElementById('shareLink') as HTMLAnchorElement;
  
    if (width <= 768) {
      shareLink.href = "sms:?body=www.studyforchange.com";
    } else {
      shareLink.href = "mailto:?body=www.studyforchange.com";
    }
  }
}
