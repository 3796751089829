import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-pre-screening-question',
  templateUrl: './new-pre-screening-question.component.html',
  styleUrls: ['./new-pre-screening-question.component.css']
})
export class NewPreScreeningQuestionComponent implements OnInit {
IsEdit:boolean;
 name:string;
  constructor() {
    this.IsEdit=false;
   }

  ngOnInit() {
  }
  test():void{

  }

}
export class study{
  name:string;
}
