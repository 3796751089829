import { ParticipantDynamicValuesModel } from 'src/app/model/participant.model';

export class participantModel {
  participantId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  phone: string;
  WhentoCall: string;
  dob: string;
  age: number;
  gender: string;
  height: number;
  weight: number;
  inches: number;
  wristCircumference: number;
  // weightLbs: number;
  studyId: string;
  studyName: string;
  responses: participantResponseModel[];
  createdDate: string;
  participantResultId: number;
  isActive: boolean;
  status: string;
  name: string;
  isMock: boolean;
  bmi: number;
  adminid: string;
  dynamicvalues: ParticipantDynamicValuesModel[];
  privacyTerms: boolean;
  smsConsent: boolean;
  personalConsent: boolean;
  phoneContact: boolean;
  emailContact: boolean;
  smsContact: boolean;
  englishProf: boolean;
  infoAgreement: boolean;
  legalSupervision: boolean;
  isAdmin: string;
  latestAttempt: string;
  attemptCount: number;
}
export enum enumResStatus {
  completed = 1,
  inProgress = 2
}
export class participantResponseModel {
  participantId: number;
  studyId: number;
  questionId: number;
  answerId: number;
  answerText: string;
  attemptNumber: number;
  questionOrder: number;
  questionCategory: string;
  freeText: string;
  result: participantResultModel;
  // participantId: number;
  // studyId: number;
  // questionId: number;
  // answerId: number;
  // answerText: string;
  // attemptNumber: number;
  // questioncategory:string;
  // result: participantResultModel[];

}
export class participantResultModel {
  preScreenerPass: boolean;
  demographicsPass: boolean;
  // questionnairePass: boolean;
  responseStatus: enumResStatus;
}
export class particpantResponse {
  participantId: number;
  participantResult: boolean;
  attemptNumber: number;
  questionType: string;
}

export class disclosure {
  participantId: number;
  studyId: number;
  disclosurePass: boolean;
  Is_Active: boolean;
  attemptNumber: number;
}

export class participantattemptModel {
  questionText: string = "";
  answer1: string;
  answer2: string;
  answer3: string;
}
export class participantExportExcel {
  StudyName: string;
  FirstName: string;
  LastName: string;
  EmailId: string;
  Phone: string;
  WhentoCall: string;
  DOB: string;
  Gender: string;
  Height: number;
  Inches: number;
  Weight: number;
  WristCircumference: number;
  AttemptDate: string;
  UserStatus: string;
  IsAdmin: string;
  AdminID: string;
}
export class participantStratification {
  age?: string;
  gender?: string;
  weight?: string;
  bmi?: string;
  height?: string;
}
