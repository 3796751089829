import { Component, OnInit } from '@angular/core';
import { configModel } from 'src/models/config.model';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-participant-header',
  templateUrl: './participant-header.component.html',
  styleUrls: ['./participant-header.component.css']
})
export class ParticipantHeaderComponent implements OnInit {

  configModel: configModel;
  hcontact: string;
  
  constructor(private configService: ConfigService) { }

  ngOnInit() {
    // const adminRedirectLink = document.getElementById('adminRedirect');
    // if (adminRedirectLink) {
    //   adminRedirectLink.addEventListener('click', (event: Event) => {
    //     event.preventDefault();
        
    //     const currentUrl = window.location.href.split('#')[0];
    //     window.location.href = `${currentUrl}admin`;
    //   });
    // }
    
    this.configService.getAppConfig()
      .subscribe(
        (resp: configModel) => {
        this.configModel = resp
        localStorage.setItem('config', JSON.stringify(resp));
        let ck = new configModel();
        this.hcontact = ck.getConfigkeyname('Header_Contact');
      });
  }

}
