import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/services/config.service';
import { configModel } from 'src/models/config.model';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ParticipantService } from 'src/app/service/participant.service';

@Component({
  selector: 'app-participanthome',
  templateUrl: './participant_home.component.html',
  styleUrls: ['./participant_home.component.css']
})
export class ParticipantHomeComponent implements OnInit {

  configModel: configModel;
  hcontact: string;
  aboutus: string;
  originalAboutus: string;
  editConfigVisible = false;
  isEditable: boolean = false;
  isAdminUser = false;
  @ViewChild('aboutUsTextarea') aboutUsTextarea: ElementRef;
  imageSrc: string;
  isLoading: boolean = false;
  isUploading: boolean = false;
  uploadProgress: number = 0;

  constructor(private configService: ConfigService, private router: Router, private http: HttpClient, private participantService: ParticipantService) {
    this.isAdminUser = localStorage.getItem('isAdminUser') === 'true';
  }

  ngOnInit() {
    this.loadImage();
    this.configService.getAppConfig()
      .subscribe(
        (resp: configModel) => {
          this.configModel = resp
          localStorage.setItem('config', JSON.stringify(resp));
          let ck = new configModel();
          this.aboutus = ck.getConfigkeyname('About_Us');
          this.originalAboutus = this.aboutus;
        });
  }

  saveAboutUs() {
    this.configService.updateAboutUsConfig('About_Us', this.aboutus)
      .subscribe(res => {
        if (res === 1) {
          this.originalAboutus = this.aboutus;
          this.blurTextarea();
          this.isEditable = false;
        }
      });
  }

  cancelEdit() {
    this.aboutus = this.originalAboutus;
    this.blurTextarea();
    this.isEditable = false;
  }

  editConfigData() {
    this.isEditable = true;
    this.focusTextarea();
  }

  focusTextarea() {
    this.aboutUsTextarea.nativeElement.focus();
  }

  blurTextarea() {
    this.aboutUsTextarea.nativeElement.blur();
  }

  triggerFileInput(): void {
    const fileInput: HTMLElement = document.getElementById('fileInput') as HTMLElement;
    fileInput.click();
  }
  
  updateImageSrc(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append('image', file);
      this.isLoading = true;
      this.isUploading = true;
      this.uploadProgress = 0;
  
      this.participantService.uploadFile(formData).subscribe(
        (event) => {
          if (event.status === 'progress') {
            this.uploadProgress = event.message;
          } else if (event.status === 'completed') {
            const newImageUrl = event.message.imageUrl + '?t=' + new Date().getTime();
            const imgElement = document.getElementById('siteImage') as HTMLImageElement;
            imgElement.onload = () => {
                this.isLoading = false;
            };
            imgElement.src = newImageUrl;
            this.isUploading = false;
          }
        },
        (error) => {
          console.error('Upload failed', error);
          this.isLoading = false;
          this.isUploading = false;
        }
      );
    }
  }
  
  loadImage(): void {
      this.isLoading = true;
      this.participantService.getImage().subscribe(response => {
        this.imageSrc = response.imageUrl;
        this.isLoading = false;
    });
  }
}
