import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { studyModel } from 'src/models/study.model';
import { participantModel, disclosure, participantResponseModel, participantattemptModel } from 'src/models/participant.model';
import { questionModel } from 'src/models/question.model';
import { AppConfig } from 'src/app/app.config';
import { filterPrescreened, applicantsFilter } from 'src/app/model/gridFilter.model';
import { ParticipantDynamicValuesModel } from 'src/app/model/participant.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  constructor(private http: HttpClient) { }

  //Get Study Details
  getDummyDetail(): Observable<studyModel> {
    return this.http.get<studyModel>(AppConfig.appsettings.apiRoot + '/values').pipe(catchError(this.handleError));
  }

  //Get Study Details
  getStudyDetail(studyId: string): Observable<studyModel> {
    return this.http.get<studyModel>(AppConfig.appsettings.apiRoot + '/study/' + studyId).pipe(catchError(this.handleError));
  }

  getStudyDetailsForParticipant(studyId: string): Observable<studyModel> {
    return this.http.get<studyModel>(AppConfig.appsettings.apiRoot + '/Studiesforparticipants', {
      params: new HttpParams()
        .set("id", studyId.toString())
    }).pipe(catchError(this.handleError));
  }

  getAllParticipants(applicantsFilter: applicantsFilter): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/applicants', applicantsFilter)
      .pipe(catchError(this.handleError));
  }

  getPrescreenedApplicants(preScreenedFilter: filterPrescreened): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/prescreenedapplicants', preScreenedFilter)
      .pipe(catchError(this.handleError));
  }
  getFileterPrescreened(prescreenedFilter: filterPrescreened): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/prescreenedfilter', prescreenedFilter)
      .pipe(catchError(this.handleError));
  }
  getParticipantList(studyId: string): Observable<participantModel[]> {
    return this.http.get<participantModel[]>(AppConfig.appsettings.apiRoot + '/participant?studyId=' + studyId).pipe(catchError(this.handleError));
  }
  getParticipantListForExport(studyId: string): Observable<any> {
    return this.http.get<any[]>(AppConfig.appsettings.apiUrl + 'studyexport?studyId=' + studyId).pipe(catchError(this.handleError));
  }

  getParticipantAttempt(participantId: string, studyId: string): Observable<participantattemptModel> {
    return this.http.get<participantattemptModel>(AppConfig.appsettings.apiRoot + '/participantattempt?participId=' + participantId + '&studyId=' + studyId).pipe(catchError(this.handleError));
  }

  //Get Study List 
  getStudyList(): Observable<studyModel[]> {
    return this.http.get<studyModel[]>(AppConfig.appsettings.apiRoot + '/study?isPublish=true').pipe(catchError(this.handleError));
  }
  //Get disclosure questions
  getDisclosure(): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/question?Category=Disclosure').pipe(catchError(this.handleError));
  }

  //Get list of questions by study id and category prescreener
  getQuestions(studyid: string, Adminid: boolean = true, Isrefered: string = "false"): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/questionstudy?studyId=' + studyid + '&questioncategory=PreScreener' + '&AdminId=' + Adminid + '&IsRefered=' + Isrefered).pipe(catchError(this.handleError));
  }

  getStudyQuestions(studyid: string): Observable<any[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiUrl + 'questions?studyId=' + studyid).pipe(catchError(this.handleError));
  }

  getParticipantResponse(studyid: number, participantId: string, questionId: number): Observable<any> {
    return this.http.get<any>(AppConfig.appsettings.apiUrl + 'participantresponseanswers?studyId=' + studyid + '&participantId=' + participantId + '&questionId=' + questionId).pipe(catchError(this.handleError));
  }

  //Get list of questions by study id and category general
  getGeneralQuestions(studyid: string): Observable<questionModel[]> {
    return this.http.get<questionModel[]>(AppConfig.appsettings.apiRoot + '/questionstudy?studyId=' + studyid + '&questioncategory=General').pipe(catchError(this.handleError));
  }

  getParticipantDynamicValues(studyId: string, Adminid: boolean = true, Isrefered: string = "false"): Observable<ParticipantDynamicValuesModel[]> {
    return this.http.get<ParticipantDynamicValuesModel[]>(AppConfig.appsettings.apiRoot + '/getDynamicParticipantValues', {
      params: new HttpParams()
        .set("studyId", studyId)
        .set("AdminId", String(Adminid))
        .set("IsRefered", Isrefered)
    }).pipe(catchError(this.handleError));
  }
  //Save Demographics Info
  postDemographics(participant: participantModel): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/participant', participant).pipe(catchError(this.handleError));
  }

  //Save Question Answer
  postPreScreenerQA(participantResponse: participantResponseModel): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/participantresponse', participantResponse).pipe(catchError(this.handleError));
  }
  postmockPreScreenerQA(participantResponse: participantResponseModel): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/mockparticipantresponse', participantResponse).pipe(catchError(this.handleError));
  }


  //Save general question answer
  //Save Question Answer
  postGeneralrQA(participantResponse: participantResponseModel): Observable<string> {
    return this.http.post<string>(AppConfig.appsettings.apiRoot + '/participantgeneralresponse', participantResponse).pipe(catchError(this.handleError));
  }
  //Save Disclosure
  postDisclosure(disclosure: disclosure): Observable<disclosure> {
    return this.http.put<disclosure>(AppConfig.appsettings.apiRoot + '/participantresult', disclosure).pipe(catchError(this.handleError));
  }

  getApplicantsPhoneFilter(applicantsFilter: applicantsFilter): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/applicantsPhoneFilter', applicantsFilter)
      .pipe(catchError(this.handleError));
  }
  getApplicantsWhentoCallFilter(applicantsFilter: applicantsFilter): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/applicantsWhentoCallFilter', applicantsFilter)
      .pipe(catchError(this.handleError));
  }
  getApplicantsAgeFilter(applicantsFilter: applicantsFilter): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/applicantsAgeFilter', applicantsFilter)
      .pipe(catchError(this.handleError));
  }
  getApplicantsStudynumberFilter(applicantsFilter: applicantsFilter): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/applicantsStudynumberFilter', applicantsFilter)
      .pipe(catchError(this.handleError));
  }
  getApplicantsStatusFilter(applicantsFilter: applicantsFilter): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/applicantsStatusFilter', applicantsFilter)
      .pipe(catchError(this.handleError));
  }

  getPrescreenedAgeFilter(filterPrescreened: filterPrescreened): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/prescreenedAgeFilter', filterPrescreened)
      .pipe(catchError(this.handleError));
  }
  getPrescreenedStudynumberFilter(filterPrescreened: filterPrescreened): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/prescreenedStudynumberFilter', filterPrescreened)
      .pipe(catchError(this.handleError));
  }
  getPrescreenedDateCompletedFilter(filterPrescreened: filterPrescreened): Observable<participantModel[]> {
    return this.http.post<participantModel[]>(AppConfig.appsettings.apiRoot + '/prescreenedDateCompletdFilter', filterPrescreened)
      .pipe(catchError(this.handleError));
  }
  deleteParticipants(participantIds: string): Observable<boolean> {
    return this.http.delete(AppConfig.appsettings.apiUrl + 'deleteapplicants?participantIds=' + participantIds)
      .pipe(
        map((response: Object) => {
          return true;
        }),
        catchError(this.handleError)
      );
  }
  handleError(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }
}
