import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/services/config.service';
import { configModel } from 'src/models/config.model';
import { ParticipantService } from 'src/services/participant.service';
import { AuthGaurdService } from 'src/services/auth-gaurd.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationTrackingService } from 'src/services/navigation-tracking.service';

@Component({
  selector: 'app-participanthome',
  templateUrl: './participant_home.component.html',
  styleUrls: ['./participant_home.component.css']
})
export class ParticipantHomeComponent implements OnInit {

  configModel: configModel;
  hcontact: string;
  aboutus: string;
  originalAboutus: string;
  editConfigVisible = false;
  isEditable: boolean = false;
  isAdminUser = false;
  @ViewChild('aboutUsTextarea') aboutUsTextarea: ElementRef;
  constructor(private configService: ConfigService, private router: Router) {
    this.isAdminUser = sessionStorage.getItem('isAdminUser') === 'true';
  }

  ngOnInit() {
    this.configService.getAppConfig()
      .subscribe(
        (resp: configModel) => {
          this.configModel = resp
          localStorage.setItem('config', JSON.stringify(resp));
          let ck = new configModel();
          this.aboutus = ck.getConfigkeyname('About_Us');
          this.originalAboutus = this.aboutus;
        });
  }

  saveAboutUs() {
    this.configService.updateAboutUsConfig('About_Us', this.aboutus)
      .subscribe(res => {
        if (res === 1) {
          this.originalAboutus = this.aboutus;
          this.blurTextarea();
          this.isEditable = false;
        }
      });
  }

  cancelEdit() {
    this.aboutus = this.originalAboutus;
    this.blurTextarea();
    this.isEditable = false;
  }

  editConfigData() {
    this.isEditable = true;
    this.focusTextarea();
  }

  focusTextarea() {
    this.aboutUsTextarea.nativeElement.focus();
  }

  blurTextarea() {
    this.aboutUsTextarea.nativeElement.blur();
  }

  updateImageSrc(): void {
    const fileInput: HTMLInputElement = document.getElementById('fileInput') as HTMLInputElement;
    const siteImage: HTMLImageElement = document.getElementById('siteImage') as HTMLImageElement;

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          siteImage.src = event.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    }
  }  
}
