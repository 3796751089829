import { AppSettings } from './model/app-settings';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {
  static appsettings: AppSettings;

  constructor(private http: HttpClient) { }
  load() {
    const jsonFile = '../../assets/config/appsettings.json';
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: AppSettings) => {
        AppConfig.appsettings = response;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}' : ${JSON.stringify(response)}`);
      });
    });
  }

}
