// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiddenInput {
    opacity: 0;
    height: 0;
    padding: 0;
    border: none;
    position: absolute;
    z-index: 0;
    left: 45%;
}
`, "",{"version":3,"sources":["webpack://./src/app/participant/participant_disqualify/participant_disqualify.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb","sourcesContent":[".hiddenInput {\r\n    opacity: 0;\r\n    height: 0;\r\n    padding: 0;\r\n    border: none;\r\n    position: absolute;\r\n    z-index: 0;\r\n    left: 45%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
