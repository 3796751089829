export class questionModel {
  questionId: number;
  questionDesc: string;
  questionText: string = "";
  toolTip: string;
  answerType: string; //enumAnsType;
  questionCategory: string;// enumQuesCategory;
  isActive: boolean = true;
  studyId: number;
  questionOrder: number;
  answers: answers[];
  includeFreeText: boolean;
  includePredefined: boolean;
  index: number;
  freeText: string;
  removequestionId: number;
}
export class answers {
  answerId: number;
  answerText: string;
  correct_Answer?: boolean;
}
export class answersQualify {
  answerId: number;
  answerText: string;
  questionId: number;
}
export enum enumQuesCategory {
  demographics = 1,
  preScreener,
  fullDisclosure
}
export enum enumAnsType {
  Text = 1,
  Radio,
  Range
}
export class answersModel {
  answerId: number;
  answerText: string;
  isCorrect: boolean = false;
  isActive: boolean = true;
 
}
export class bindingModel {
  // "Study_ID": 25,
  //     "question_id": 8,
  //     "Question_Text": "Age",
  //     "Qtn_Stdy_ID": 15,
  //     "Answer1": "10",
  //     "Answer2": "15"

  questionId: number;
  questionText: string = "";
  //toolTip: string;
  answerType: string; //enumAnsType;
  //questionCategory: string;// enumQuesCategory;
  //isActive: boolean = true;
  //studyId: number;
  //questionOrder: number;
  //answers: answers[];
  answer1: string;
  answer2: string; 
  answer3: string;
  answer1Id: number;
  answer2Id: number;
  includeCondition: boolean = false;
  groupId: number;
}
export class questions {
  question_ID: number;
  question_Text: string;
  study_Name: string;
  study_Tag: string;
  study: string[];
  tag: string[];
  checked: boolean;
}
export class mapQuestionStudy {
  studyId: number;
  questionId: number[];
}
