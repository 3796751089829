// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-light, .bg-dark
{
    background-image: linear-gradient(to right, #250056 20%, #0076AE 48%, #0076AE 52%, #250056 100%);
}
.nav-link,
.navbar-light .navbar-nav .nav-link,
.nav-link.active,
.navbar-light .navbar-brand
{
    color: #fff;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link
{
    color: #fff;
}

agm-map {
    height: 300px;
}
.phoneNumber { 
    margin-top: 40px;
}

.button-container {
    top: 0;
    right: 0;
    margin: 10px;
}
div[name=contact-us]{
    margin-top: 2rem;
    text-align: end;
    float: right;
}
div[name=contact-us] a {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/app/_layout/participant-header/participant-header.component.css"],"names":[],"mappings":"AAAA;;IAEI,gGAAgG;AACpG;AACA;;;;;IAKI,WAAW;AACf;AACA;;IAEI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,MAAM;IACN,QAAQ;IACR,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB;AACA;IACI,cAAc;AAClB","sourcesContent":[".bg-light, .bg-dark\r\n{\r\n    background-image: linear-gradient(to right, #250056 20%, #0076AE 48%, #0076AE 52%, #250056 100%);\r\n}\r\n.nav-link,\r\n.navbar-light .navbar-nav .nav-link,\r\n.nav-link.active,\r\n.navbar-light .navbar-brand\r\n{\r\n    color: #fff;\r\n}\r\n.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link\r\n{\r\n    color: #fff;\r\n}\r\n\r\nagm-map {\r\n    height: 300px;\r\n}\r\n.phoneNumber { \r\n    margin-top: 40px;\r\n}\r\n\r\n.button-container {\r\n    top: 0;\r\n    right: 0;\r\n    margin: 10px;\r\n}\r\ndiv[name=contact-us]{\r\n    margin-top: 2rem;\r\n    text-align: end;\r\n    float: right;\r\n}\r\ndiv[name=contact-us] a {\r\n    display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
