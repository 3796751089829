// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-light, .bg-dark {
    background-image: linear-gradient(to right, #250056 20%, #0076AE 48%, #0076AE 52%, #250056 100%);
}

.nav-link, .navbar-light .navbar-nav .nav-link, .nav-link.active, .navbar-light .navbar-brand {
    color: #fff;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #fff;
}

agm-map {
    height: 300px;
}

.nav-link.active {
    /* background: #445893; */
    background: rgba(255,255,255,0.2);
}
.navbar {
    padding: 0 1rem;
    line-height: 40px;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 8px 25px;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/_layout/admin-header/admin-header.component.css"],"names":[],"mappings":"AAAA;IACI,gGAAgG;AACpG;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;AACrC;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".bg-light, .bg-dark {\r\n    background-image: linear-gradient(to right, #250056 20%, #0076AE 48%, #0076AE 52%, #250056 100%);\r\n}\r\n\r\n.nav-link, .navbar-light .navbar-nav .nav-link, .nav-link.active, .navbar-light .navbar-brand {\r\n    color: #fff;\r\n}\r\n\r\n.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {\r\n    color: #fff;\r\n}\r\n\r\nagm-map {\r\n    height: 300px;\r\n}\r\n\r\n.nav-link.active {\r\n    /* background: #445893; */\r\n    background: rgba(255,255,255,0.2);\r\n}\r\n.navbar {\r\n    padding: 0 1rem;\r\n    line-height: 40px;\r\n}\r\n.navbar-expand-lg .navbar-nav .nav-link {\r\n    padding: 8px 25px;\r\n    font-size: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
