import { Component, OnInit } from '@angular/core';
import { configModel } from 'src/models/config.model';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-participant-footer',
  templateUrl: './participant-footer.component.html',
  styleUrls: ['./participant-footer.component.css']
})
export class ParticipantFooterComponent implements OnInit {

  configModel: configModel;
  hcontact: string;
  
  constructor(private configService: ConfigService) { }

  ngOnInit() {
    this.configService.getAppConfig()
      .subscribe(
        (resp: configModel) => {
        this.configModel = resp
        localStorage.setItem('config', JSON.stringify(resp));
        let ck = new configModel();
        this.hcontact = ck.getConfigkeyname('Header_Contact');
      });
  }

}
