import { Component, OnInit } from '@angular/core';
import { ParticipantService } from 'src/services/participant.service';
import { studyModel, studyRequirementsModel } from 'src/models/study.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-participantstudylist',
  templateUrl: './participant_study_list.component.html',
  styleUrls: ['./participant_study_list.component.css']
})
export class ParticipantStudyListComponent implements OnInit {

  studyModel: studyModel[];
  studyRequirements = new studyModel;
  requirements: any;
  constructor(private participantService: ParticipantService) {
  }

  ngOnInit() {
    this.participantService.getStudyList()
    .pipe(
      map( mapvalue => {
        if(mapvalue && mapvalue.length > 0) {
          mapvalue.forEach(element => {
            element.studyRequirements = JSON.parse(element.studyRequirements);
            element.studyCommitment = JSON.parse(element.studyCommitment);
          });
        }
        return mapvalue;
      } ) 
    )
    .subscribe(resp => {
      this.studyModel = resp;
    });
  }

}
