import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-participant-study-not-found',
  templateUrl: './participant-study-not-found.component.html',
  styleUrls: ['./participant-study-not-found.component.css']
})
export class ParticipantStudyNotFoundComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  callParticipantHome(): void {
    this.router.navigateByUrl('');
  }

}
