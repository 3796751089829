import { Injectable } from '@angular/core';
import { Studydetail, studyschedule, screeningperiods, details } from '../model/studydetail';
import { Observable, map } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  study: Studydetail;
  studyschedule: studyschedule[];
  screeningperiod: screeningperiods;
  detail: details;
  constructor(private http: HttpClient) {
    this.detail = {
      summery: 'This is study 5158-Data Collection Device Study, we are looking to study healthy participants wrists and how it relates to our device. This is study 5158-Data Collection Device Study, we are looking to study healthy participants wrists and how it relates to our device. This is study 5158-Data Collection Device Study, we are looking to study healthy participants wrists and how it relates to our device',
      compensation: '$100',
      age: '18+',
      gender: 'Male and Female',
      wristcircumference: '5.1-8.3 inches'
    };

    this.screeningperiod = {
      screeningday: 'Monday through Friday',
      screeningtimes: '7:00 A.M., 9:00 A.M., 11:00 A.M., 1:00 P.M.'
    };
    this.studyschedule = [
      {
        screeningday: 'Monday',
        followupday: 'Monday'
      },
      {
        screeningday: 'Tuesday',
        followupday: 'Friday'
      }
    ];

    this.study = {
      studyid: '1',
      studyname: 'abc study name',
      detail: this.detail,
      screeningperiod: this.screeningperiod,
      studyschedule: this.studyschedule
    };
  }

  getStudyDetail(): Observable<Studydetail> {
    return this.http.get<Studydetail>(AppConfig.appsettings.apiRoot + '/study').pipe(catchError(this.handleError));
  }

  uploadFile(formData: FormData): Observable<any> {
    return this.http.post(AppConfig.appsettings.apiUrl + '/upload', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            return { status: 'progress', message: Math.round(100 * event.loaded / event.total) };
          case HttpEventType.Response:
            return { status: 'completed', message: event.body };
          default:
            return { status: 'unknown', message: event };
        }
      }),
      catchError(this.handleError)
    );
  }

  getImage(): Observable<{ imageUrl: string }> {
    return this.http.get<{ imageUrl: string }>(AppConfig.appsettings.apiUrl + 'image').pipe(catchError(this.handleError));
  }

  handleError(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }
}
